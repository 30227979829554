import React from "react";
import {
    getPackageUrl,
    getSignatureUrl,
} from "@plugins/gatsby-source-paligo/src/helpers/release";
import { createContext } from "@singlestore/fusion/react-utils/context";

type SetSelectedProps = {
    target?: string;
    releaseJson?: any;
    releaseUrl?: string;
};

type VerifySignatureContextType = {
    open: boolean;
    closeModal: () => void;
    setSelected: (props: SetSelectedProps) => void;
    target?: string;
    releaseJson?: any;
    releaseUrl?: string;
    signatureUrl?: string;
    packageUrl?: string;
    checksum?: string;
};

const [PureVerifySignatureProvider, useVerifySignature] =
    createContext<VerifySignatureContextType>();

export function VerifySignatureProvider({ children }: React.PropsWithChildren) {
    const [open, setOpen] = React.useState<boolean>(false);
    const [target, setTarget] = React.useState<string | undefined>();
    const [releaseJson, setReleaseJson] = React.useState<any>();
    const [releaseUrl, setReleaseUrl] = React.useState<string | undefined>();

    const packageObj = React.useMemo(() => {
        if (!target || !releaseJson) {
            return undefined;
        }

        return releaseJson?.packages?.[target];
    }, [releaseJson, target]);

    const packageUrl = React.useMemo(() => {
        if (!packageObj) {
            return undefined;
        }

        return getPackageUrl(packageObj.Path);
    }, [packageObj]);

    const signatureUrl = React.useMemo(() => {
        if (!packageObj?.Signature) {
            return undefined;
        }

        return getSignatureUrl(packageObj.Signature);
    }, [packageObj]);

    const checksum = React.useMemo(() => {
        return packageObj?.Sha256Sum;
    }, [packageObj]);

    const setSelected = React.useCallback(
        ({ target, releaseJson, releaseUrl }: SetSelectedProps) => {
            setTarget(target);
            setReleaseJson(releaseJson);
            setReleaseUrl(releaseUrl);
            setOpen(true);
        },
        []
    );

    const closeModal = React.useCallback(() => {
        setOpen(false);
    }, []);

    return (
        <PureVerifySignatureProvider
            value={{
                open,
                closeModal,
                setSelected,
                target,
                releaseJson,
                releaseUrl,
                signatureUrl,
                packageUrl,
                checksum,
            }}
        >
            {children}
        </PureVerifySignatureProvider>
    );
}

export { useVerifySignature };
