export const RELEASE_URL: string = "https://release.memsql.com";

export const getPackageUrl = (packagePath: string): string => {
    return `${RELEASE_URL}/${packagePath}`;
};

export const getSignatureUrl = (signaturePath: string): string => {
    return `${RELEASE_URL}/${signaturePath}`;
};

type GetJsonUrlProps = {
    channel: string;
    product: string;
    version: string;
};

export const getJsonUrl = ({
    channel,
    product,
    version,
}: GetJsonUrlProps): string => {
    return `${RELEASE_URL}/${channel}/index/${product}/${version}.json`;
};
