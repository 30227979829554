import React from "react";
import { ThemeProvider } from "@singlestore/fusion/theme-context";
import { FeedbackBannerProvider } from "contexts/FeedbackBannerContext";
import { FlyoutProvider } from "contexts/FlyoutContext";
import { NavigationProvider } from "contexts/NavigationContext";
import { NPSProvider } from "contexts/NPSContext";
import {
    PageHistoryProvider,
    usePageHistoryReducer,
} from "contexts/PageHistoryContext";
import { PathDependentProviders } from "contexts/PathDependentContext";
import { RecaptchaProvider } from "contexts/RecaptchaContext";
import { VerifySignatureProvider } from "contexts/VerifySignatureContext";
import {
    Sentiment,
    WasThisHelpfulProvider,
} from "contexts/WasThisHelpfulContext";
import { HeadProvider } from "react-head";

import { TooltipProvider } from "components/Tooltip/Tooltip";

type WrapRootElementProps = {
    element: React.ReactElement;
    pathname: string;
};

export function Providers({ children }: React.PropsWithChildren) {
    const [navigationPanePosition, setNavigationPanePosition] =
        React.useState<number>(0);

    const [wasThisHelpfulPopoverOpen, setWasThisHelpfulPopoverOpen] =
        React.useState<boolean>(false);
    const [sentiment, setSentiment] = React.useState<Nullable<Sentiment>>(null);
    const [reason, setReason] = React.useState<Nullable<string>>(null);
    const [page, setPage] = React.useState<number>(0);

    const [feedbackModalOpen, setFeedbackModalOpen] =
        React.useState<boolean>(false);
    const [feedback, setFeedback] = React.useState<string>("");

    const navigationContextValue = {
        navigationPanePosition,
        setNavigationPanePosition,
    };

    const wasThisHelpfulContextValue = {
        popoverOpen: wasThisHelpfulPopoverOpen,
        setPopoverOpen: setWasThisHelpfulPopoverOpen,
        sentiment,
        setSentiment,
        reason,
        setReason,
        page,
        setPage,
    };

    const feedbackBannerContextValue = {
        feedbackModalOpen,
        setFeedbackModalOpen,
        feedback,
        setFeedback,
    };

    const [pageHistoryState, pageHistoryDispatch] = usePageHistoryReducer();

    const pageHistoryContextValue = {
        state: pageHistoryState,
        dispatch: pageHistoryDispatch,
    };

    return (
        <ThemeProvider>
            <RecaptchaProvider>
                <TooltipProvider>
                    <HeadProvider headTags={[]}>
                        <PageHistoryProvider value={pageHistoryContextValue}>
                            <NavigationProvider value={navigationContextValue}>
                                <WasThisHelpfulProvider
                                    value={wasThisHelpfulContextValue}
                                >
                                    <FeedbackBannerProvider
                                        value={feedbackBannerContextValue}
                                    >
                                        <FlyoutProvider>
                                            <NPSProvider>
                                                <VerifySignatureProvider>
                                                    {children}
                                                </VerifySignatureProvider>
                                            </NPSProvider>
                                        </FlyoutProvider>
                                    </FeedbackBannerProvider>
                                </WasThisHelpfulProvider>
                            </NavigationProvider>
                        </PageHistoryProvider>
                    </HeadProvider>
                </TooltipProvider>
            </RecaptchaProvider>
        </ThemeProvider>
    );
}

export function WrapRootElement({ element, pathname }: WrapRootElementProps) {
    // we do this because the wrapRootElement in gatsby-ssr gives us a pathname
    // but the wrapRootElement in gatsby-browser doesn't. some of our contexts
    // depend on the pathname so for the hydration step we render those contexts
    // in the components (document.tsx and product.tsx) where they will have
    // access to pathname

    if (pathname) {
        return (
            <Providers>
                <PathDependentProviders pathname={pathname}>
                    {element}
                </PathDependentProviders>
            </Providers>
        );
    }

    return <Providers>{element}</Providers>;
}
